export interface Row {
  name: string
  date: string
  file: string
}

const data: Row[] = [
  {
    name: "Tir en salle",
    date: "2020-02-24",
    file: "meilleures-perf-salle-24-02-2020.pdf",
  },
  {
    name: "Tir à l'arc en extérieur 50",
    date: "2019-08-01",
    file: "meilleures-perf-tae-50-01-08-2019.pdf",
  },
  {
    name: "Tir à l'arc en extérieur",
    date: "2019-08-01",
    file: "meilleures-perf-tae-01-08-2019.pdf",
  },
  {
    name: "Tir campagne",
    date: "2019-12-11",
    file: "meilleures-perf-campagne-11-12-2019.pdf",
  },
  {
    name: "Tir 3D",
    date: "2019-12-11",
    file: "meilleures-perf-3d-11-12-2019.pdf",
  },
  {
    name: "Tir nature",
    date: "2019-12-11",
    file: "meilleures-perf-nature-11-12-2019.pdf",
  },
]

export default data
